import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { PATHS } from '../../constants'
// @ts-ignore
import Logo from '../../assets/logo_white.svg'
import { Button } from "antd";

// @ts-ignore
import { FacebookOutlined, InstagramOutlined, TwitterOutlined, XOutlined } from "@ant-design/icons";

interface Props {
}

const Footer = ({ }: Props) => {
  //const { i18n } = useTranslation()
  return (
    <div className="fixed-bottom footer">
      <div className="container d-flex flex-row w-100 justify-content-between pt-2 pb-2">
        <div className="d-flex align-items-center">
          <Link to={PATHS.SIGN_IN}>
            <img src={Logo} className="logo ms-5" />
          </Link>
          <Button type="link" className="ms-5 me-3 fontBreymontLight">privacy</Button>
          <Button type="link" className="me-3 fontBreymontLight">contact us</Button>
        </div>
        <div className="d-flex align-items-center">
          <InstagramOutlined className="text-white me-2"/>
          <FacebookOutlined className="text-white me-2" />
          <XOutlined className="text-white me-3"/>
          <small className="me-3 text-white fw-lighter fontBreymontLight">all rights reserved©</small>
        </div>
      </div>
    </div>
  )
}

export default Footer
