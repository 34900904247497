import { Button, Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useReduxDispatch } from "../../../helpers";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../../store/auth/authActions";
import { useNavigate } from "react-router-dom";

function LoginContainer() {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [langUpdate, setlangUpdate] = useState(0)
  const [modalVisible, setModalVisible] = useState(false)

  //@ts-ignore
  const { loading, userInfo, error } = useSelector((state) => state.auth)


  const navigate = useNavigate()

  console.log('')

  useEffect(() => {
    if (userInfo) {
      setTimeout(()=>{
        navigate('/listing')
      },200)
    }
  }, [navigate, userInfo])

  const [passwordIsVisible, setPasswordVisible] = useState<boolean>(false)

  const getPasswordInputType = (passwordIsVisible: boolean) => {
    return passwordIsVisible ? 'text' : 'password'
  }

  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordIsVisible)
  }

  const submit = (data:any) => {
    //@ts-ignore
    dispatch(userLogin(data))
  }

  return (

    <div className="container vh-100">
      <Header hideSignin={true} hideUpload={true} showSignup/>
      <div className="d-flex flex-column align-items-center justify-content-center h-75">
        <Form form={form} layout="vertical" hideRequiredMark onFinish={submit}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={'Email'}
                name="email"
                className="login-form-input"
                rules={[{ required: true, max: 255, type: 'email', whitespace: true }]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <Input
                  className={"login-input"}
                  placeholder={'Email Address'}
                  size="large"
                  type="email"
                />
              </Form.Item>
            </Col>
            <Col span={24} className="mt--50">
              <Form.Item
                label={'Password'}
                name="password"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                  },
                ]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <Input
                  size="large"
                  className={"login-input"}
                  placeholder={'Enter Password'}
                  style={{ paddingRight: 50 }}
                  type={getPasswordInputType(passwordIsVisible)}
                />
              </Form.Item>
            </Col>
            <Col span={24} className={"mt-3"}>
              <div className="w-100 d-flex align-items-center">
                <Button htmlType="submit" className="m-auto login-btn">Login</Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <Footer/>
    </div>
  )
}

export default LoginContainer;
