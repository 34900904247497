import React, { useEffect, useState } from "react";
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import './styles/main.scss';
import 'bootstrap/dist/css/bootstrap.css';
import AppRouter from './router'
import store from "./store/store";
import { ConfigProvider } from "antd";
import { GoogleOAuthProvider } from '@react-oauth/google';

export default function () {
  const [isLoading, setLoading] = useState(false)
  const language = "en";
  
  const fakeRequest = () => {
    return new Promise(resolve => setTimeout(() => resolve(true), 1500))
  }
  
  const hideLoader = async () => {
    await fakeRequest()
    const el = document.querySelector('.loader')
    if (el) {
      el.remove()
      setLoading(false)
    }
  }
  useEffect(() => {
    hideLoader()
  }, [])
  
  /*if (isLoading) {
    return <Splash/>
  }*/
  
  return (
    <GoogleOAuthProvider clientId="1022734685876-ulnnq8l9tveagqp6uo89svrdcgs7bi3s.apps.googleusercontent.com">
      <Provider store={store}>
        <ConfigProvider direction={language === 'ar' ? 'rtl': 'ltr'}>
          <React.Fragment>
            <AppRouter/>
          </React.Fragment>
        </ConfigProvider>
      </Provider>
    </GoogleOAuthProvider>
  )
}
