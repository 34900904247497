import { Button, Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useReduxDispatch } from "../../../helpers";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { registerUser } from "../../../store/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants";

function SignupContainer() {
  //@ts-ignore
  const { loading, userInfo, error, success } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [langUpdate, setlangUpdate] = useState(0)
  const [modalVisible, setModalVisible] = useState(false)
  const [passwordIsVisible, setPasswordVisible] = useState<boolean>(false)

  const navigate = useNavigate()

  useEffect(() => {
    // redirect authenticated user to profile screen
    if (userInfo) navigate('/user-profile')
    // redirect user to login page if registration was successful
    if (success) navigate(PATHS.SIGN_IN)
  }, [navigate, userInfo, success])

  const getPasswordInputType = (passwordIsVisible: boolean) => {
    return passwordIsVisible ? 'text' : 'password'
  }

  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordIsVisible)
  }

  const submit = (data: any) => {
    // @ts-ignore
    dispatch(registerUser(data))
  }

  return (

    <div className="vh-100 w-100">
      <Row>
        <Col span={12} className="auth-section-header ">
        </Col>
        <Col span={12}>
          <div className="w-75 m-auto">
            <Header hideLogo={true} hideSignin={false} hideUpload={false}/>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center h-75 w-100">
            <Form form={form} layout="vertical" hideRequiredMark onFinish={submit}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label={'Email'}
                    name="email"
                    className="login-form-input"
                    rules={[{ required: true, max: 255, type: 'email', whitespace: true }]}
                    validateTrigger={['onChange', 'onBlur']}
                  >
                    <Input
                      className={"login-input"}
                      placeholder={'Email Address'}
                      size="large"
                      type="email"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} className="mt--50">
                  <Form.Item
                    label={'Password'}
                    name="password"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                      },
                    ]}
                    validateTrigger={['onChange', 'onBlur']}
                  >
                    <Input
                      size="large"
                      className={"login-input"}
                      placeholder={'Enter Password'}
                      style={{ paddingRight: 50 }}
                      type={getPasswordInputType(passwordIsVisible)}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} className={"mt-3"}>
                  <div className="w-100 d-flex justify-content-start">
                    <Button htmlType="submit" className="m-auto upload-btn">Create An Account</Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default SignupContainer;
