import { useSelector } from 'react-redux'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'
import { PATHS } from "../constants";
import { useEffect } from "react";

const ProtectedRoute = () => {
  const navigate = useNavigate()
  const { userInfo, userToken } = useSelector((state) => {
    console.log('auth', state)
    //@ts-ignore
    return state.auth
  })
  useEffect(() => {
    if(!userToken){
      navigate('/login/options')
    }
  }, [userToken]);

  // show unauthorized screen if no user is found in redux store
  if (!userToken) {
    return (
      <div className='unauthorized'>
        <h1>Unauthorized :(</h1>
        <span>
          <NavLink to={PATHS.HOME}>Login</NavLink> to gain access
        </span>
      </div>
    )
  }

  return <Outlet />
}

export default ProtectedRoute
