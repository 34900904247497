import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl =
  process.env.NODE_ENV !== 'production'
    ? 'http://localhost:5005/v1/'
    : 'https://api.livelock.app:5000/v1'

export const trackApi = createApi({
  reducerPath: 'trackApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.userToken
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        return headers
      }
    },
  }),
  endpoints: (build) => ({
    getUserTracks: build.query({
      query: () => ({
        url: '/tracks',
        method: 'GET',
      }),
    }),
    createTrack: build.query({
      query: () => ({
        url: 'users/tracks',
        method: 'GET',
      }),
    }),
  }),
})

// export react hook
export const { useGetUserTracksQuery } = trackApi
