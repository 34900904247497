/* eslint-disable */
// @ts-nocheck
import { Button, Col, Form, Input, Layout, message, Row, Select, Tag, Upload, UploadProps, } from "antd";
import React, { useCallback, useRef, useState } from "react";
import { WaveForm, WaveSurfer } from "wavesurfer-react";
import { useReduxDispatch } from "../../../helpers";
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { RcFile } from "antd/es/upload";
import { PlayCircleOutlined, CloseCircleOutlined, CloseOutlined, PauseCircleOutlined } from '@ant-design/icons';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { createNewTrack } from "../../../store/track/trackActions";
import { PATHS } from "../../../constants";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// @ts-ignore
import africa from '../../../assets/udio/africa.mp3';
import { useGetUserTracksQuery } from "../../../services/auth/authService";

const { Content } = Layout;
const { Dragger } = Upload;
const { Option } = Select;

function UploadContainer() {
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  const { userInfo, userToken } = useSelector((state) => {
    console.log('auth', state)
    //@ts-ignore
    return state.auth
  })
  const [form] = Form.useForm()
  const [artworkLoading, setArtworkLoading] = useState(false);
  const [trackLoading, setTracLoading] = useState(true);
  const [artworkUrl, setArtworkUrl] = useState('');
  const [trackUrl, setTrackUrl] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [playState, setPlayState] = useState(false);
  const [isLoading, setLoading] = useState(false);

  let { data, isFetching,refetch } = useGetUserTracksQuery('userDetails', {
    pollingInterval: 90000000,
  })

  const generes = [];
  const moods = [];
  data?.forEach((track)=>{
    const tags = track.tags?.split(',');
    tags.forEach((tag)=>{
      generes.push({label: tag, value: tag})
    })
  });

  data?.forEach((track)=>{
    const modes = track.moods?.split(',');
    modes.forEach((mode)=>{
      moods.push({label: mode, value: mode})
    })
  })

  const wavesurferRef = useRef();
  const playRef = useRef();

  const handleWSMount = useCallback(
    (waveSurfer) => {
      if(trackUrl) {
        wavesurferRef.current = waveSurfer;

        if (wavesurferRef.current) {
          console.log('trackurl', trackUrl)
          wavesurferRef.current.load(trackUrl);

          wavesurferRef.current.on("ready", () => {
            console.log("WaveSurfer is ready");
            setIsLoaded(true);
          });

          wavesurferRef.current.on("loading", (data) => {
            console.log("loading --> ", data);
          });
          wavesurferRef.current.on("loading", (data) => {
            console.log("loading --> ", data);
          });
          wavesurferRef.current.on("play", (data) => {
            setPlayState(true)
          });
          wavesurferRef.current.on("finish", (data) => {
            setPlayState(false)
          });
          wavesurferRef.current.on("pause", (data) => {
            setPlayState(false)
          });

          if (window) {
            window.surferidze = wavesurferRef.current;
          }
        }
      }
    },
    [trackUrl],
  );

  const play = useCallback(() => {
    wavesurferRef.current.playPause();
  }, []);




  const submit = async (data: any) => {
    setLoading(true);
    const payload = { ...data }
    payload.track = trackUrl;
    payload.artwork = artworkUrl;
    payload.tags = payload.tags?.join(',');
    payload.moods = payload.moods?.join(',');
    payload.user_id = userInfo.id
    // @ts-ignore
    await dispatch(createNewTrack(payload))
    navigate(PATHS.LISTING)
    setLoading(false)
  }

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    action: 'https://api.livelock.app:5000/v1/users/upload/media',
    beforeUpload: (file) => {
      console.log('file', file)
      const isImage = file.type.includes('image/');
      const size = file.size / (1024 * 1024);
      if (!isImage) {
        message.error(`${file.name} is not a image file`);
      }
      if(size> 1){
        message.error(`File size cannot be more then 1 mb`);
      }
      return (isImage && size <1) || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info.file.status === 'uploading') {
        setArtworkLoading(true);
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        console.log('info', info.file.response.data.url)
        setArtworkUrl(info.file.response.data.url)
        setArtworkLoading(false)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const trackProps: UploadProps = {
    name: 'file',
    multiple: true,
    showUploadList: true,
    action: 'https://api.livelock.app:5000/v1/users/upload/media',

    beforeUpload: (file) => {
      console.log('file2', file)
      const isAudio = file.type.includes('audio/');
      const size = file.size / (1024 * 1024);
      console.log('file size', size)
      if (!isAudio) {
        message.error(`${file.name} is not a audio file`);
        setTrackUrl(null)
      }
      else if(size> 2){
        message.error(`File size cannot be more then 2 mb`);
        setTrackUrl(null);
      }
      return (isAudio && size < 2) || Upload.LIST_IGNORE;
    },

    onChange(info) {
      if (info.file.status === 'uploading') {
        //setArtworkLoading(true);
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        console.log('info', info)
        setTrackUrl(info.file.response.data.url)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const stringToColour = (str: any) => {
    let hash = 0;
    str.split('').forEach(char => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash)
    })
    let colour = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff
      colour += value.toString(16).padStart(2, '0')
    }
    return colour
  }

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={stringToColour(label)}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <div className="container">
      <Header hideUpload/>
      <Content className="upload-container">
        <Form
          onFinish={submit}
          form={form}
          layout="vertical">
          <Row>
            <Col span={12} className="mt-5">
              <Row className="mt-5">
                <Col span={24}>
                  <Form.Item
                    name="artWork"
                    className="login-form-input"
                    rules={[{ required: true, message: 'Art work is required' }]}
                    validateTrigger={['onChange', 'onBlur']}
                  >

                    <Dragger
                      {...props}
                      showUploadList={false}
                      listType="picture-card"
                      style={{ width: 200, backgroundColor: '#8B8899' }}>
                      {artworkUrl ? <img src={artworkUrl} alt="avatar" style={{ height: 120 }}/> : artworkLoading ?
                        <LoadingOutlined/> :
                        <p
                          className="ant-upload-drag-icon"
                          style={{
                            width: '100%',
                            height: 120,
                            display: "flex",
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                          <UploadOutlined className="text-white"/><span
                          className="ms-2 text-white">Upload Art work</span>
                        </p>
                      }
                    </Dragger>
                  </Form.Item>
                </Col>
                <Col span={20}>
                  <Form.Item
                    name="track"
                    className="login-form-input"
                    rules={[{ required: true, message: 'Art work is required' }]}
                    validateTrigger={['onChange', 'onBlur']}
                  >
                    <Upload {...trackProps}>
                      <div className="ant-upload-drag-icon mt-5">
                        <UploadOutlined className="text-white"/><span className="ms-2 text-white">Upload track</span>
                      </div>
                    </Upload>
                  </Form.Item>
                </Col>
                {trackUrl && <Col span={20}>
                  <Row align={'center'} justify={'middle'}>
                    <Col span={2}>
                      <Button shape="circle" type={'clear'} onClick={play} icon={playState ? <PauseCircleOutlined /> : <PlayCircleOutlined />} ></Button>
                    </Col>
                    <Col span={18}>
                      <WaveSurfer
                        progressColor={'#FF3180'}
                        onMount={handleWSMount}
                        cursorColor="#FF3180"

                        container="#waveform"
                      >
                        <WaveForm/>
                        {/*<div id="timeline" />*/}
                      </WaveSurfer>
                    </Col>
                    <Col span={4}>
                      <Button style={{backgroundColor: 'transparent', borderColor: 'transparent'}} type={'text'} onClick={play} icon={<CloseOutlined />} ></Button>
                    </Col>
                  </Row>
                </Col>}
              </Row>
            </Col>
            <Col span={12} className="mt-5">
              <Col span={24}>
                <Form.Item
                  label={'Track name'}
                  name="name"
                  className="login-form-input"
                  rules={[{ required: true, message: 'Track name is required' }]}
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <Input
                    className={"login-input"}
                    placeholder={'Track name'}
                    size="large"
                    type="text"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={'Artist'}
                  name="artist"
                  className="login-form-input"
                  rules={[{ required: true, message: 'Artist name is required' }]}
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <Input
                    className={"login-input"}
                    placeholder={'Artist'}
                    size="large"
                    type="text"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={'Tags'}
                  name="tags"
                  className="login-form-input"
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <Select
                    mode="tags"
                    tagRender={tagRender}
                    className="tags-input"
                    style={{ width: '100%' }}
                    placeholder="Gener"
                    options={generes}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={'Moods'}
                  name="moods"
                  className="login-form-input"
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <Select
                    mode="tags"
                    tagRender={tagRender}
                    className="tags-input"
                    style={{ width: '100%' }}
                    placeholder="Tags Mode"
                    options={moods}
                  >
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={'BMP'}
                  name="bmp"
                  className="login-form-input"
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <Input
                    className={"login-input"}
                    placeholder={'bpm'}
                    size="large"
                    type="text"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Button loading={isLoading} htmlType="submit" className="m-auto login-btn">Upload Track</Button>
              </Col>
            </Col>
          </Row>
        </Form>
      </Content>
      <Footer/>
    </div>
  )
}

export default UploadContainer;
