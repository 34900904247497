import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { message } from "antd";

const backendURL =
  process.env.NODE_ENV !== 'production'
    ? 'http://localhost:5005/v1'
    : 'https://api.livelock.app:5000/v1'

export const createNewTrack = createAsyncThunk(
  'track/create',
  async (data, { rejectWithValue, getState }) => {
    try {
      const token = getState().auth.userToken
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Bearer ${token}`
        },
      }

      await axios.post(
        `${backendURL}/tracks`,
        data,
        config
      )
      message.success('Successfully added new track');
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)
export const incrementDownloadCount = createAsyncThunk(
  'track/create',
  async (data, { rejectWithValue, getState }) => {
    try {
      const token = getState().auth.userToken
      const { id } = data;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Bearer ${token}`
        },
      }
      
      await axios.post(
        `${backendURL}/tracks/incrementDownload`,
        {id},
        config
      )
      message.success('Successfully update doenload count');
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)


