export default {
  ROOT: '/',
  NOT_FOUND: '404',
  SERVER_ERROR: '500',
  SIGN_IN: '/login',
  HOME: '/home',
  SIGN_IN_OPTIONS: '/login/options',
  SIGN_UP: '/signup',
  LISTING: '/listing',
  UPLOAD: '/upload',
  UPDATE_PASSWORD: '/update-password',
  UPDATE_EMAIL: '/update-email',
  RESET_PASSWORD: '/reset-password',
  RESTORE_PASSWORD: '/restore-password/:token',
  ACTIVATE_ACCOUNT: '/activate-account/:token',
  SPECIAL_CLIENTS: '/p/:id',
  REGISTER_SUCCESS: '/register-success',

  //Client Routes
  CLIENT_PROFILE: '/client/client-profile',
  CLIENT_PROFILE_PREVIEW: '/client/client-profile-preview',
}
