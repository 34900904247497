/* eslint-disable */
// @ts-nocheck
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Avatar, Button, Col, Form, Input, List, Row, Select, Space, Table, Tag, Modal } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useReduxDispatch } from "../../../helpers";
import AuthLayout from "../../../components/Layouts/AuthLayout";
import { Link, useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants";

const { Search } = Input;
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { ColumnsType } from "antd/es/table";
import { useGetUserTracksQuery } from "../../../services/auth/authService";
import { WaveForm, WaveSurfer } from "wavesurfer-react";
import { PlayCircleOutlined, DownloadOutlined, PauseCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { createNewTrack, incrementDownloadCount } from "../../../store/track/trackActions";


interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string | string[];
  moods?: string | string[];
}


function ListingContainer() {
  const dispatch = useReduxDispatch()
  const [form] = Form.useForm()
  const [langUpdate, setlangUpdate] = useState(0)
  const [modalVisible, setModalVisible] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false)
  const navigate = useNavigate();

  const wavesurferRef = useRef([]);
  const [playButtonStats, setPlayButtonState] = useState([]);
  const [sortedData, setSortedData] = useState([]);

  const [mood, setMood] = useState('');
  const [gener, setGener] = useState('');
  const [search, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const { userInfo, userToken } = useSelector((state) => {
    //@ts-ignore
    return state.auth
  })

  const showModal = () => {
    setModalOpen(true);
  };

  const handleOk = () => {
    setModalOpen(false);
    navigate('/login/options')
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  let { data, isFetching,refetch } = useGetUserTracksQuery('userDetails', {
    pollingInterval: 900000,
  })

  const onDownload = (id:number, url: string) => {
    console.log('data', data)
    window.open(url,'_blank')
    dispatch(incrementDownloadCount({id})).then(()=>{
      refetch();
    })

  };


  useEffect(() => {
    if(data) {
      let _data = [...data];
      _data = _data.filter((track) =>
        (!search?.trim() || track?.name?.toLowerCase().includes(search.toLowerCase())) &&
        (!gener?.trim() || track?.tags?.toLowerCase().includes(gener.toLowerCase())) &&
        (!mood?.trim() || track?.moods?.toLowerCase().includes(mood.toLowerCase()))
      )
      setFilteredData(_data)
    }
  }, [data, gener, search, mood]);

  const generes = [];
  const moods = [];
  data?.forEach((track)=>{
    const tags = track.tags?.split(',');
    tags.forEach((tag)=>{
      generes.push({label: tag, value: tag})
    })
  });

  data?.forEach((track)=>{
    const modes = track.moods?.split(',');
    modes.forEach((mode)=>{
      moods.push({label: mode, value: mode})
    })
  })

  const handleSurerMount = (waveSurfer, id, track) => {
    wavesurferRef.current[id] = waveSurfer;

    if (wavesurferRef.current[id]) {
      wavesurferRef.current[id].load(track);

      wavesurferRef.current[id].on("ready", () => {
        setIsLoaded(true);
      });

      wavesurferRef.current[id].on("loading", (data) => {
        console.log("loading --> ", data);
      });

      wavesurferRef.current[id].on("play", (data) => {
        playButtonStats[id] = true;
        setPlayButtonState([...playButtonStats, ])
      });
      wavesurferRef.current[id].on("pause", (data) => {
        playButtonStats[id] = false;
        setPlayButtonState([...playButtonStats, ])
      });
      wavesurferRef.current[id].on("finish", (data) => {
        playButtonStats[id] = false;
        setPlayButtonState([...playButtonStats, ])
      });

      if (window) {
        window.surferidze = wavesurferRef.current[id];
      }
    }
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <div className="d-flex">
        <img src={record.artwork}
             style={{
               minHeight: 65,
               minWidth: 65,
               maxWidth: 65,
               maxHeight: 65,
               marginRight: 10,
               border: '0px solid #E5E4E2'
             }}>
        </img>
        <div>
          <a><span style={{fontWeight: 'bold'}}>{text}</span></a>
          <div>{record.artist}</div>
          <div style={{fontSize: 10}}>{moment().format('DD MMM, YYYY').toUpperCase()}</div>
        </div>
      </div>,
    },
    {
      title: 'Track',
      dataIndex: 'track',
      key: 'track',
      render: (text, object) => <div style={{ flexDirection: 'row', width: '100%' }}>
        <Button
          onClick={() => {
            wavesurferRef.current[object.id].playPause();
          }}
          style={{
            backgroundColor: '#FF3180',
            height: 40,
            width: 40,
            position: 'absolute', left: 0, top: 0, bottom: 0, marginTop: 20
          }} shape="circle" icon={playButtonStats[object.id] ? <PauseCircleOutlined/> : <PlayCircleOutlined/>}></Button>
        <div style={{ marginLeft: 40 }}>
          <WaveSurfer
            progressColor={'#FF3180'}
            onMount={(webserver) => {
              console.log('object ', object)
              handleSurerMount(webserver, object.id, object.track)
            }}
            cursorColor="#FF3180"
            barHeight={1}
            height={50}
            width={450}
            container={`#waveform${object.id}`}
          >
            <WaveForm id={`waveform${object.id}`}/>
            {/*<div id="timeline" />*/}
          </WaveSurfer>
        </div>
      </div>,
    },
    {
      title: 'Moods',
      key: 'moods',
      dataIndex: 'moods',
      render: (_, { moods }) => {

        return (
          <>
            {
              //@ts-ignore
              moods?.split(',').map((tag) => {
                let color = stringToColour(tag);
                if (tag === 'loser') {
                  color = 'volcano';
                }
                return (
                  <Tag color={color} key={tag} style={{marginBottom: 5}}>
                    {tag.toUpperCase()}
                  </Tag>
                );
              })}
          </>
        )
      },
    },
    {
      title: 'Tags',
      key: 'tags',
      dataIndex: 'tags',
      render: (_, { tags }) => {

        return (
          <>
            {
              //@ts-ignore
              tags?.split(',').map((tag) => {
                let color = stringToColour(tag);
                if (tag === 'loser') {
                  color = 'volcano';
                }
                return (
                  <Tag color={color} key={tag} style={{marginBottom: 5}}>
                    {tag.toUpperCase()}
                  </Tag>
                );
              })}
          </>
        )
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <div>
          <div>
            <DownloadOutlined color={'#FF3180'} style={{ color: '#FF3180', marginRight: 5 }}/>
            <span>{`${record.downloads} ${record.downloads > 1 ? 'users' : 'user'}`}</span>
          </div>
          <div>
            {userInfo &&
              <Button
                onClick={()=>{onDownload(record.id, record.track)}}
                style={{backgroundColor:'#FF3180', fontSize: 9}}
                className="me-5 mt-3 fontAkiraExpanded">Download</Button>
            }
            {!userInfo &&
              <Button
                onClick={showModal}
                style={{backgroundColor:'#FF3180', fontSize: 9}}
                className="me-5 mt-3 fontAkiraExpanded">Download</Button>
           }
          </div>
        </div>
      ),
    },
  ];

  const stringToColour = (str: any) => {
    let hash = 0;
    str.split('').forEach(char => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash)
    })
    let colour = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff
      colour += value.toString(16).padStart(2, '0')
    }
    return colour
  }

  return (
    <div className="container">
      <Header/>
      <div className=" track-list">
        <Row className="mt-5 mb-4">
          <Col span="6">
            <Search
              onChange={(e)=>{setSearchTerm(e.target.value)}}
              className={"search-box"} placeholder="Search" style={{ width: 280 }}/>
          </Col>
          <Col span="3">
            <Select
              placeholder="Gener"
              style={{ width: 120 }}
              options={generes}
              onChange={setGener}
            />
          </Col>
          <Col span="3">
            <Select
              placeholder="Mode"
              style={{ width: 120 }}
              options={moods}
              onChange={setMood}
              allowClear={true}
            />
          </Col>
        </Row>
        <Table
          className="tracks-table"
          showHeader={false}
          columns={columns}
          dataSource={filteredData}
        />
      </div>
      <Footer/>
      <Modal
        okText={'SIGN IN'}
        className="download-modal"
        title="Do you want to download music?" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>Sign in to your account to be able to view the full library & download</p>
      </Modal>
    </div>
  )
}

export default ListingContainer;
