import React, { useEffect, useMemo, useState } from 'react'
import { Router, Route, BrowserRouter, Routes,Navigate } from 'react-router-dom'
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import { useReduxDispatch } from "../helpers";
import { useSelector } from "react-redux";

import LoginTwoPaneContainer from "../modules/auth/containers/LoginTwoPane.container";
import HomeContainer from "../modules/home/containers/Home.container";
import ListingContainer from "../modules/listing/containers/Listing.container";
import UploadContainer from "../modules/upload/containers/Upload.container";
import LoginOptionsContainer from "../modules/auth/containers/LoginOptions.container";
import * as Path from "path";
import { PATHS } from "../constants";
import LoginContainer from "../modules/auth/containers/Login.container";
import SignupContainer from "../modules/auth/containers/Signup.container";
import ProtectedRoute from "./ProtectedRoute";

interface Props {
  children?: any
}

const AppRouter = ({}: Props) => {
  const dispatch = useReduxDispatch()
  const [transInit, setTransInit] = useState(false)
  useEffect(() => {
  }, [])
  useEffect(() => {
    //@ts-ignore
    i18n
      .use(Backend)
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        fallbackLng: ["en"],
        detection: { checkWhitelist: true },
        debug: false,
        whitelist: ['ar', 'en'],
        interpolation: {
          escapeValue: false
        }
      }).then(() => {
      setTransInit(true)
    });
  }, [])
  return (
    <BrowserRouter>
      <Routes>
        <Route key={'root'} path={PATHS.ROOT} element={<HomeContainer/>}/>
        <Route key={'login'} path={PATHS.SIGN_IN} element={<LoginContainer/>}/>
        <Route key={'signup'} path={PATHS.SIGN_UP} element={<SignupContainer/>}/>
        <Route key={'home'} path={PATHS.HOME} element={<HomeContainer/>}/>
        <Route key={'login-options'} path={PATHS.SIGN_IN_OPTIONS} element={<LoginOptionsContainer/>}/>
        <Route key={'listing'} path={PATHS.LISTING} element={<ListingContainer />} />
        <Route element={<ProtectedRoute />}>
          <Route key={'upload'} path={PATHS.UPLOAD} element={<UploadContainer />} />
        </Route>
        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
