import { createSlice } from '@reduxjs/toolkit'

// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null

const initialState = {
  loading: false,
  tracks: [],
  userToken,
  error: null,
  success: false,
}

const trackSlice = createSlice({
  name: 'track',
  initialState,
  reducers: {
    setTracks: (state, { payload }) => {
      state.tracks = payload
    },
  }
})

export const { setTracks} = trackSlice.actions

export default trackSlice.reducer
