import { useSelector } from "react-redux";
import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

function HomeContainer() {
  const navigate = useNavigate();
  const { userInfo, userToken } = useSelector((state) => {
    //@ts-ignore
    return state.auth
  })
  useEffect(() => {
   /* if(userToken){
      navigate('/listing')
    }*/
  }, [userToken]);

  const submit = () => {

  }

  return (
    <div className="container">
      <Header/>
      <div className="w-100 mt-5 pb-5">
        <Row className="home-section-margin-top" justify={'space-between'}>
          <Col span={24} lg={{span: 10}} className="">
            <div className="home-section-title mt-3 fontAkiraExpanded">
              FIRST ARABIC<br/>MUSIC PLATFORM
            </div>
            <div className="home-section-description mt-4 fontBreymontLight">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
            </div>
          </Col>
          <Col span={24} lg={{span: 10}} className="">
            <img
              src={require('./../../../assets/home_img_1.png')}
              className="home-img mt-3"
              style={{float: "right"}}
            />
          </Col>
        </Row>

        <Row className="home-section-margin-top" justify={'space-between'}>
          <Col span={24} lg={{span: 10}} className="mt-3">
            <img
              src={require('./../../../assets/home_img_2.png')}
              className="home-img mt-3"
              style={{float: "left"}}
            />
          </Col>
          <Col span={24} lg={{span: 10}} className="">
            <div className="home-section-title mt-3 fontAkiraExpanded">
              Lorem Ipsum
            </div>
            <div className="home-section-description mt-4 fontBreymontLight">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
            </div>
          </Col>
        </Row>

        <Row className="home-section-margin-top" justify={'space-between'}>
          <Col span={24} lg={{span: 10}} >
            <div className="home-section-title mt-3 fontAkiraExpanded">
              Lorem Ipsum
            </div>
            <div className="home-section-description mt-4 fontBreymontLight">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
            </div>
          </Col>
          <Col span={24} lg={{span: 10}}>
            <img
              src={require('./../../../assets/home_img_3.png')}
              className="home-img mt-3"
              style={{float: "left"}}
            />
          </Col>
        </Row>
        <Row className="home-section-margin-top mb-5" justify={'space-between'}>
          <Col span={24} lg={{span: 10}} className="">
            <img
              src={require('./../../../assets/home_img_4.png')}
              className="home-img mt-3"
              style={{float: "left"}}
            />
          </Col>
          <Col span={24} lg={{span: 10}} className="">
            <div className="home-section-title mt-3 fontAkiraExpanded">
              Lorem Ipsum
            </div>
            <div className="home-section-description mt-4 fontBreymontLight">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
            </div>
          </Col>
        </Row>
      </div>
      <Footer/>
    </div>
  )
}

export default HomeContainer;
