import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { message } from "antd";

const backendURL =
  process.env.NODE_ENV !== 'production'
    ? 'http://localhost:5005/v1'
    : 'https://api.livelock.app:5000/v1'

export const userLogin = createAsyncThunk(
  'auth/login',
  async ({ email, password }, { rejectWithValue, getState, dispatch }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.post(
        `${backendURL}/auth/login`,
        { email, password },
        config
      )

      // store user's token in local storage
      await localStorage.setItem('userToken', data.tokens.access.token)
      console.log('data.tokens.token',data.tokens.access.token)
      //localStorage.getItem('userToken')
      
      message.success('Login Successful');

      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        message.error(error.response.data.message);
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const userLogout = createAsyncThunk(
  'auth/logout',
  async ({}, { rejectWithValue, getState, dispatch }) => {
    try {
      // configure header's Content-Type as JSON
      
      
      // store user's token in local storage
      await localStorage.removeItem('userToken');
      //localStorage.getItem('userToken')
      message.success('Logout Successfully');
      
      return {}
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        message.error(error.response.data.message);
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const socialLogin = createAsyncThunk(
  'auth/login',
  async (userData, { rejectWithValue, getState, dispatch }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      
      const { data } = await axios.post(
        `${backendURL}/auth/social-login`,
        userData,
        config
      )
      
      // store user's token in local storage
      await localStorage.setItem('userToken', data.tokens.access.token)
      console.log('data.tokens.token',data.tokens.access.token)
      //localStorage.getItem('userToken')
      
      message.success('Login Successful');
      
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        message.error(error.response.data.message);
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const registerUser = createAsyncThunk(
  'auth/register',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      
      const { data } = await axios.post(
        `${backendURL}/auth/register`,
        { email, password },
        config
      )
      message.success('Registered Successfully');
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        message.error(error.response.data.message);
        return rejectWithValue(error.response.data.message)
      } else {
        message.success(error.message);
        return rejectWithValue(error.message)
      }
    }
  }
)


