import { Button, Form } from "antd";
import React, { useEffect, useState } from "react";
import { AppleFilled, GoogleOutlined } from '@ant-design/icons';
import { useReduxDispatch } from "../../../helpers";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
// @ts-ignore
import FacebookIcon from "../../../assets/facebook-icon.svg";
import { FacebookLoginClient } from '@greatsumini/react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// @ts-ignore
import EmailIcon from "../../../assets/@-icon.svg";
import { PATHS } from "../../../constants";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { socialLogin, userLogin } from "../../../store/auth/authActions";
import { useSelector } from "react-redux";

function HomeContainer() {
  const dispatch = useReduxDispatch()
  const navigate = useNavigate();
  const [form] = Form.useForm()
  const [code, setCode] = useState('');
  const [tokens, setTokens] = useState({});

  const appId = '402077096011925';

  //@ts-ignore
  const { loading, userInfo, error } = useSelector((state) => state.auth)

  const [passwordIsVisible, setPasswordVisible] = useState<boolean>(false)



  const getPasswordInputType = (passwordIsVisible: boolean) => {
    return passwordIsVisible ? 'text' : 'password'
  }

  const responseFacebook = (response) => {
    onLogin(
      'facebook',
      response.id,
      {access_token: response.accessToken, refresh_token: response.accessToken},
      {email : response.email || 'sample@example.com',given_name: response.name?.split(' ')[0], family_name: response.name?.split(' ').length>1 ?  response.name?.split(' ')[1] : ''}
    )
  }

  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordIsVisible)
  }

  useEffect(() => {
   //loadFB();
  }, []);



  useEffect(() => {
    console.log('userInfo', userInfo)
    if (userInfo) {
      setTimeout(()=>{
        navigate('/listing')
      },200)
    }
  }, [navigate, userInfo])

  const loadFB = async ()=>{
    FacebookLoginClient.clear();
    await FacebookLoginClient.loadSdk('en_US');
    FacebookLoginClient.init({ appId: appId, version: 'v16.0' });
  }

  const onLogin = (type: string, code: string, tokens: any, profile: any) => {
    const data = {
      code,
      //@ts-ignore
      access_token: tokens.access_token,
      //@ts-ignore
      refresh_token: tokens.refresh_token,
      email: profile.email,
      first_name:profile.given_name,
      last_name:profile.family_name,
      password: tokens.access_token,
      type
    };
    //@ts-ignore
    dispatch(socialLogin(data))
  }

  const facebookLogin = ()=>{
    FacebookLoginClient.login((res) => {
      console.log(res.authResponse);
    },{
      scope: 'public_profile, email, name, picture',
    });
  }

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse) => {
      console.log('codeResponse ',codeResponse);
      const tokens = await axios.post(
        'https://api.livelock.app:5000/v1/auth/google-auth', {
          code: codeResponse.code,
        });
      setCode(codeResponse.code);
      //@ts-ignore
      setTokens(tokens.tokens);

      //@ts-ignore
      console.log('tokens', tokens.data.tokens);

      //@ts-ignore
      onLogin('google', codeResponse.code, tokens.data.tokens, tokens.data.profile);
    },
    onError: errorResponse => console.log(errorResponse),
  });

  return (
    <div className="container vh-100">
      <Header hideSignin={true} hideUpload={true}/>
      <div className="d-flex flex-column align-items-center justify-content-center h-75">
        <Button
          onClick={googleLogin}
          type="default" icon={<GoogleOutlined/>} className="login-options-button fontAkiraExpanded">Continue with Google</Button>
        <Button type="default" icon={<AppleFilled/>} className="login-options-button fontAkiraExpanded">Continue with Apple</Button>
        <FacebookLogin
          appId="402077096011925"
          autoLoad={false}
          fields="name,email,picture"
          callback={responseFacebook}
          render={renderProps => (
            <Button
              onClick={renderProps.onClick}
              type="default" icon={<img alt={'facebook icon'} width={8} src={FacebookIcon}/>}
              className="login-options-button fontAkiraExpanded">Continue with Facebook</Button>
          )}
        />
        <Button
          onClick={()=>{navigate(PATHS.SIGN_IN)}}
          type="default" icon={<img alt={'facebook icon'} width={13} src={EmailIcon}/>} className="login-options-button fontAkiraExpanded">Continue with Email</Button>
        <h3 className="text-white">or</h3>
        <Button
          onClick={()=>{navigate(PATHS.SIGN_UP)}}
          className="mt--100 fontAkiraExpanded" type="default" >Create an account</Button>

      </div>
      <Footer/>
    </div>
  )
}

export default HomeContainer;
