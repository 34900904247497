import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { PATHS } from '../../constants'
// @ts-ignore
import Logo from '../../assets/logo_white.svg';
// @ts-ignore
import UserMenu from '../../assets/user_menu.png';
import { Button, Dropdown, MenuProps, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useGetUserDetailsQuery } from "../../services/auth/authService";
import { setCredentials, logout } from "../../store/auth/authSlice";
import { userLogout } from "../../store/auth/authActions";

interface Props {
  hideUpload?: boolean,
  hideSignin?: boolean,
  showSignup?: boolean,
  hideLogo?:boolean
}

const Header = ({ hideUpload = false, hideSignin=false, showSignup=false, hideLogo=false }: Props) => {
  const navigate = useNavigate();
  //const { i18n } = useTranslation()
  const { userInfo, userToken } = useSelector((state) => {
    //@ts-ignore
    return state.auth
  })
  console.log('userInfor', userInfo, userToken)
  const dispatch = useDispatch()
  const { data, isFetching } = useGetUserDetailsQuery('userDetails', {
    pollingInterval: 900000,
  })

  useEffect(() => {
    if (data) dispatch(setCredentials(data))
  }, [data, dispatch])

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Button
          onClick={async ()=>{
            localStorage.setItem('userToken','');
            //@ts-ignore
            await dispatch(logout())
            setTimeout(()=>{
              window.location.href='/'
            },200)
          }}
          className="logout" type="text">Logout</Button>
      ),
    }
  ];

  return (
    <div className="d-flex flex-row w-100 justify-content-between pt-2">
      <Link to={PATHS.ROOT}>
        {!hideLogo &&
          <img alt={'logo'} src={Logo} className="logo mt-3" />
        }
      </Link>
      <div className="d-flex align-items-center">
        {!hideUpload && userInfo?.role === 'admin' && <Link to={PATHS.UPLOAD}><Button className="me-5 mt-3 fontAkiraExpanded">Upload Your Music</Button></Link>}
        {!hideUpload && !userInfo && <Link to={PATHS.LISTING}><Button className="me-5 mt-3 fontAkiraExpanded">Music List</Button></Link>}
        {!hideSignin && !userToken && <Link to={PATHS.SIGN_IN_OPTIONS} style={{float: 'right', color: '#80CBD0'}} className="fontAkiraExpanded mt-3">
          SIGN IN
        </Link>}
        {userToken && <span>{`${userInfo?.first_name || ''} ${userInfo?.last_name || ''}`}</span>}
        {showSignup && <Link to={PATHS.SIGN_UP} style={{float: 'right', color: '#80CBD0'}}>
          CREATE NEW ACCOUNT
        </Link>}
        {userToken && <Dropdown menu={{ items }}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <img className="mt-3" src={require("../../assets/user_menu.png")} style={{width: 40}}/>
            </Space>
          </a>
        </Dropdown>}
      </div>
    </div>
  )
}

export default Header
