import { configureStore } from '@reduxjs/toolkit'
import authReducer from './auth/authSlice'
import trackReducer from './track/trackSlice'
import { authApi } from '../services/auth/authService'
import { trackApi } from "../services/track/trackService";

const store = configureStore({
  reducer: {
    auth: authReducer,
    track: trackReducer,
    [authApi.reducerPath]: authApi.reducer,
    [trackApi.reducerPath]: trackApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authApi.middleware),
})

export default store
